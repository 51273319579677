define("discourse/plugins/z-communiteq-features/discourse/components/show-backup-info", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="padding: 12px; background-color: #d1f0ff; color: #222;">
  Off-site backups for disaster recovery are created every 24 hours. This page only shows the backups you have made yourself.
  </div>
  {{#if this.showMigrateInfo}}
  <div style="padding: 12px; background-color: #fffbd6; color: #222;">
  <b>Important: </b>If you are migrating from another hosting provider then we recommend you to read
  <a href="https://www.communiteq.com/discoursehosting/kb/migrate-from-another-provider/" target="_blank">our migration guide</a> before
  attempting to restore a backup.
  </div>
  {{/if}}
  
  */
  {
    "id": "gp6OHImn",
    "block": "[[[10,0],[14,5,\"padding: 12px; background-color: #d1f0ff; color: #222;\"],[12],[1,\"\\nOff-site backups for disaster recovery are created every 24 hours. This page only shows the backups you have made yourself.\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showMigrateInfo\"]],[[[10,0],[14,5,\"padding: 12px; background-color: #fffbd6; color: #222;\"],[12],[1,\"\\n\"],[10,\"b\"],[12],[1,\"Important: \"],[13],[1,\"If you are migrating from another hosting provider then we recommend you to read\\n\"],[10,3],[14,6,\"https://www.communiteq.com/discoursehosting/kb/migrate-from-another-provider/\"],[14,\"target\",\"_blank\"],[12],[1,\"our migration guide\"],[13],[1,\" before\\nattempting to restore a backup.\\n\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"div\",\"if\",\"b\",\"a\"]]",
    "moduleName": "discourse/plugins/z-communiteq-features/discourse/components/show-backup-info.hbs",
    "isStrictMode": false
  });
  const FEATURED_CLASS = "show-backup-info";
  class ShowBackupInfo extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get showMigrateInfo() {
      return this.siteSettings.bootstrap_mode_enabled;
    }
  }
  _exports.default = ShowBackupInfo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ShowBackupInfo);
});